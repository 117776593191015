<template>
  <div>
    <b-card class="employee-view">
      <b-tabs
        vertical
        nav-wrapper-class="nav-vertical"
        
        >
          <b-tab>
          <template #title >
            <strong style="margin-right: auto;" >Employee Information</strong>
          </template>
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col md="6" class="mb-2">
                <h5 class="mb-0">Account Details</h5>
                <small class="text-muted">
                  Employee Account Details.
                </small>
              </b-col>
              <b-col md="6">
                <div class="demo-inline-spacing">
                  <b-form-checkbox disabled v-model="data_local.status" switch inline>
                    Status
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col md="6">
                <b-form-group label="Title" label-for="title">
                  {{data_local.title}}
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Name" label-for="name">
                  {{data_local.name}}
                </b-form-group>
              </b-col>            
              <b-col md="6">
                <b-form-group label="Nickname" label-for="nick-name">
                  {{data_local.nickname}}
                </b-form-group>
              </b-col>
              
              <b-col md="6">
                <b-form-group label="Email" label-for="email">
                  {{data_local.email}}
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Gender" label-for="last-name">
                  {{data_local.gender}}
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Mobile" label-for="mobile">
                  {{data_local.mobile}}
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Extension" label-for="extension">
                  {{data_local.extention}}
                </b-form-group>
              </b-col>  
              <b-col md="6">
                <b-form-group label="Employee Id" label-for="employee id">
                  {{data_local.employee_id}}
                </b-form-group>
              </b-col>
              
              <b-col md="6">
                <b-form-group
                  label="Confirmation Date"
                  label-for="confirmation-date"
                >
                {{data_local.confirmation_date}}
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Date Of Joining" label-for="date-of-joining">
                  {{data_local.date_of_join}}
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Probation Period (Days)"
                  label-for="probation period"
                >
                {{data_local.probation_days}}
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Employee Type" label-for="employee_type">
                  {{data_local.employee_type}}
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
          </b-row>
          </validation-observer>
          </b-tab>
          <b-tab>
            <template #title >
              <strong style="margin-right: auto;" >Personal Information</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Date of Birth" label-for="date-of-birth">
                    {{data_local.date_of_birth}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Blood Group" label-for="blood-group">
                    {{data_local.blood_group}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Father Name" label-for="father-name">
                    {{data_local.father_name}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Marital Status" label-for="marital-status">
                    {{data_local.marital_status}}
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="data_local.marital_status && data_local.marital_status == 'married' ">
                  <b-form-group label="Date Of Marriage" label-for="date-of-marriage">
                    {{data_local.date_of_marriage}}
                  </b-form-group>
                </b-col>
                
                <b-col md="6">
                  <b-form-group label="Spouse Name" label-for="spouse-name">
                    {{data_local.spouse_name}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Place of Birth" label-for="birth-place">
                    {{data_local.place_of_birth}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Country of Origin" label-for="origin-county">
                    {{data_local.orgin_country}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Religion" label-for="religion">
                    {{data_local.religion}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Is International Employee" label-for="is-international-employee">
                    {{data_local.is_international_employee}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Is Physically Challenged" label-for="Is-Physically-Challenged">
                    {{data_local.is_physically_challenged}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Personal Email" label-for="personal-email">
                    {{data_local.personal_email}}
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
            </b-row>
            </validation-observer>
          </b-tab>

          <b-tab>
            <template #title >
              <strong style="margin-right: auto;" >Joining Details</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Confirmation Date"
                    label-for="confirmation-date"
                  >
                    {{data_local.confirmation_date}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Date Of Joining" label-for="date-of-joining">
                    {{data_local.date_of_join}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Probation Period (Days)"
                    label-for="probation period"
                  >
                    {{data_local.probation_days}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Previous Company Experience (Years)"
                    label-for="previous-company-experience"
                  >
                    {{data_local.previous_company_experience}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Notice Period (Days)"
                    label-for="notice-period"
                  >
                    {{data_local.notice_period_days}}
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </b-tab>
          <b-tab >
            <template #title >
              <strong style="margin-right: auto;" >Current Position</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Roles">
                    {{data_local.role}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Designation">
                    {{data_local.designation}}
                  </b-form-group>
                </b-col>
                <b-col md="6">

                  <b-form-group label="Division">
                    {{data_local.division}}
                  </b-form-group>
                </b-col>
                <b-col md="6">

                  <b-form-group label="Department">
                    {{data_local.department}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Reporting Manager"
                    label-for="reporting manager"
                  >
                    {{data_local.reporting_manager_name}}
                  </b-form-group>
                </b-col>
                <b-col md="6">

                  <b-form-group label="Communication Channel Type">
                    {{data_local.channel_type}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Communication Channel ID"
                    label-for="communication-channel"
                  >
                    {{data_local.channel_id}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Shifts">
                    {{data_local.shift}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Location" label-for="location">
                    {{data_local.branch_location}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Work From Home Location" label-for="wfh">
                    {{data_local.wfh_location}}
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </b-tab>

          <b-tab >
            <template #title >
              <strong style="margin-right: auto;" >Leave Balance</strong>
            </template>
            <div class="w-100 table-responsive">
        <table class="w-100 mt-2 table" v-if="employeeLeavesList.length > 0">
          <thead>
            <tr class="text-uppercase">
              <td>leave type</td>
              <td>granted</td>
              <td>consumed</td>
              <td>approval pending</td>
              <td>balance</td>
              <td>encashed count</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in employeeLeavesList" v-bind:key="item.id">
              <td>
                {{ item.leave_type }}
              </td>
              <td>
                {{ item.granted }}
              </td>
              <td>
                {{ item.consumed }}
              </td>
              <td>
                {{ item.approval_pending }}
              </td>
              <td>
                {{ item.balance }}
              </td>
              <td>
                {{ item.encashed_count }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="" v-if="!employeeLeavesList"></div>
         </b-tab>


         <b-tab >
            <template #title >
              <strong style="margin-right: auto;" >Related Records</strong>
            </template>
            <div class="w-100 table-responsive">
              <table class="w-100 mt-2 table">
          <thead>
            <tr class="text-uppercase">
              <th>Title</th>
              <th>Records</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Leave Applications</td>
              <!-- this.$router.push({ path: '/community', query: queryParams }) -->
              <td><router-link target="_blank" :to="{ name: 'leave-applications', query: { employee_hash_id: userId }}">
          {{ data_local.name }} Leave Applications
        </router-link></td>
            </tr>
            <tr>
              <td>Regularizations</td>
              <td><router-link 
              :to="{ name: 'regularize-applications', query: { employee_hash_id: userId } }" 
              target="_blank">
              {{ data_local.name }} Regularizations Applications
            </router-link>
          </td>
            </tr>
            <tr>
              <td>Posts</td>

             <td><a 
            :href="generateUrl('community', { employee_hash_id: userId,type:'posts' })" 
            target="_blank"
          >
            {{ data_local.name }} Posts
          </a></td>

            </tr>
            <tr>
              <td>Polls</td>
              <td><a 
            :href="generateUrl('community', { employee_hash_id: userId,type:'poll'  })" 
            target="_blank"
          >
            {{ data_local.name }} Polls
          </a></td>
            </tr>
            <tr>
              <td>Events</td>
              <td><a 
            :href="generateUrl('community', { employee_hash_id: userId,type:'event' })" 
            target="_blank"
          >
            {{ data_local.name }} Events
          </a></td>
            </tr>
            <tr>
              <td>Comp Off</td>
              <td><router-link target="_blank" :to="{ name: 'compensatory-off-applications', query: { employee_hash_id : userId  }}">
                {{ data_local.name }} Comp Off Applications
        </router-link></td>
            </tr>
            <tr>
              <td>OKR</td>
              <td><router-link target="_blank" :to="{ name: 'all-okrs', query: { employee_name: data_local.name }}">
                {{ data_local.name }} OKR
        </router-link></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="" v-if="!employeeLeavesList"></div>
         </b-tab>
          <b-tab>
            <template #title >
              <strong style="margin-right: auto;" >Employee Identity</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col md="6">

                  <b-form-group label="Identity Type">
                    {{data_local.identity_type}}
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="data_local.identity_type && data_local.identity_type == 'aadhaar'">
                  <b-form-group
                    label="Aadhaar Number"
                    label-for="aadhaar"
                  >
                    {{data_local.aadhaar}}
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="data_local.identity_type && data_local.identity_type == 'pan_number'">
                  <b-form-group
                    label="PAN Number"
                    label-for="pan_number"
                  >
                    {{data_local.pan_number}}
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="data_local.identity_type && data_local.identity_type == 'passport'">
                  <b-form-group
                    label="Passport"
                    label-for="passport"
                  >
                    {{data_local.passport}}
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="data_local.identity_type && data_local.identity_type == 'social_security_number'">
                  <b-form-group
                    label="Social Security Number"
                    label-for="social_security_number"
                  >
                    {{data_local.social_security_number}}
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </b-tab>

          <b-tab>
            <template #title >
              <strong style="margin-right: auto;" >Address</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">Address Info</h5>
                  <small class="text-muted">Enter Employee Address Info.</small>
                </b-col>
                <h5 class="col-12 d-flex justify-content-between">
                  <div>Current Address</div>
                </h5>
                <b-col md="6">
                  <b-form-group label="Address Line 1" label-for="address-line-1">
                    {{data_local.location.add_line_1}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Address Line 2" label-for="address-line-2">
                    {{data_local.location.add_line_2}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Postcode" label-for="postcode">
                    {{data_local.location.post_code}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="City" label-for="city">
                    {{data_local.location.city}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="State" label-for="state">
                    {{data_local.location.state}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Country" label-for="country">
                    {{data_local.location.country}}
                  </b-form-group>
                </b-col>
                <h5 class="col-12 d-flex justify-content-between">
                  <div>Permanent Address</div>
                </h5>
                <b-col md="6">
                  <b-form-group
                    label="Address Line 1"
                    label-for="permanent-address-address-line-1"
                  >
                    {{data_local.permanent_address.add_line_1}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Address Line 2"
                    label-for="permanent-address-address-line-2"
                  >
                    {{data_local.permanent_address.add_line_2}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Postcode"
                    label-for="permanent-address-postcode"
                  >
                    {{data_local.permanent_address.post_code}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="City" label-for="permanent-address-city">
                    {{data_local.permanent_address.city}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="State" label-for="permanent-address-state">
                    {{data_local.permanent_address.state}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Country" label-for="permanent-address-country">
                    {{data_local.permanent_address.country}}
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </b-tab>
          <b-tab>
            <template #title >
              <strong style="margin-right: auto;" >Checklist</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col class="md-8" />
                <b-col class="md-4">
                  <b-form autocomplete="off" class="faq-search-input">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="searchbar"
                        v-model="faqSearchQuery"
                        placeholder="Search Checklist..."
                      />
                    </b-input-group>
                  </b-form>
                </b-col>
              </b-row>
      
              <section id="faq-tabs" class="mt-2 mb-2">
                <div v-if="faqData.length">
                  <b-tabs
                    vertical
                    content-class="col-12 col-md-8 col-lg-9"
                    pills
                    nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
                    nav-class="nav-left"
                  >
                    <b-tab
                      v-for="(obj, index) in faqData"
                      :key="index"
                      :active="!index"
                    >
                      <!-- title -->
                      <template #title>
                        <span class="font-weight-bold">{{ obj.title }}</span>
                      </template>
      
                      <div v-if="obj">
                        <div class="d-flex align-items-center mt-1 mb-2">
                          <div>
                            <h4 class="mb-0">
                              {{ obj.title }}
                            </h4>
                          </div>
                        </div>
                        <b-list-group>
                          <b-list-group-item
                            v-for="(data, indexes) in obj.descriptions"
                            :key="indexes"
                          >
                            <b-form-checkbox
                              v-model="data_local.checklist_description_ids"
                              :value="data.hashid"
                              disabled
                              >{{ data.description }}</b-form-checkbox
                            ></b-list-group-item
                          >
                        </b-list-group>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
                <div v-else class="text-center">
                  <span>No Checklist Found</span>
                </div>
              </section>
            </validation-observer>
          </b-tab>
          <b-tab>
            <template #title >
              <strong style="margin-right: auto;" >Emergency Contact</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Emergency Contact Name"
                    label-for="emergency-contact-name"
                  >
                    {{data_local.emergency_contact_name}}
                  </b-form-group>
                </b-col>
    
                <b-col md="6">
                  <b-form-group
                    label="Emergency Contact Number"
                    label-for="emergency-contact-number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="emergency contact number"
                      rules="numeric"
                    >
                    {{data_local.emergency_contact_number}}
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Relationship" label-for="relationship">
                    {{data_local.relationship}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Address Line 1"
                    label-for="emergency-contact-address-address-line-1"
                  >
                    {{data_local.emergency_contact_address.add_line_1}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Address Line 2"
                    label-for="emergency-contact-address-address-line-2"
                  >
                    {{data_local.emergency_contact_address.add_line_2}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Postcode"
                    label-for="emergency-contact-address-postcode"
                  >
                    {{data_local.emergency_contact_address.post_code}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="City" label-for="emergency-contact-address-city">
                    {{data_local.emergency_contact_address.city}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="State" label-for="emergency-contact-address-state">
                    {{data_local.emergency_contact_address.state}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Country" label-for="emergency-contact-address-country">
                    {{data_local.emergency_contact_address.country}}
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </b-tab>
          <b-tab>
            <template #title >
              <strong style="margin-right: auto;" >Background Check</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Verification Status" label-for="verification_status">
                    {{data_local.background_check.verification_status}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Agency Name" label-for="agency_name">
                    {{data_local.background_check.agency_name}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Remark">
                    {{data_local.background_check.remarks}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Verified on Date"
                    label-for="verified-date"
                  >
                    {{data_local.background_check.verified_on}}
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </b-tab>
          <b-tab >
            <template #title >
              <strong style="margin-right: auto;" >Remarks</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Employee Remarks">
                    {{data_local.remarks}}
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </b-tab>
          <b-tab >
            <template #title >
              <strong style="margin-right: auto;" >Position History</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col md="12" >
                  <div style="border: 2px solid #38B22D;padding: 15px;border-radius: 50px 20px;" 
                class="mt-1" v-for="(history,k) in data_local.position_history" :key="k">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Designation">
                      {{history.designation}}
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Effective From Date"
                      label-for="effective-from-date"
                    >
                      {{history.effective_from}}
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Effective To Date"
                      label-for="effective-to-date"
                    >
                      {{history.effective_to}}
                    </b-form-group>
                  </b-col>
                </b-row>
                </div>
                </b-col>
              </b-row>
            </validation-observer>
          </b-tab>
          <b-tab >
            <template #title >
              <strong style="margin-right: auto;" >Previous Employments</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col md="12" >
                  <div style="border: 2px solid #38B22D;padding: 15px;border-radius: 50px 20px;" 
                class="mt-1" v-for="(previous_employeement,k) in data_local.previous_employeements" :key="k">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Company Name" label-for="company-name">
                    
                      {{previous_employeement.company_name}}
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Designation">
                      {{previous_employeement.designation}}
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Effective From Date"
                      label-for="effective-from-date"
                    >
                      {{previous_employeement.effective_from}}
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Effective To Date"
                      label-for="effective-to-date"
                    >
                      {{previous_employeement.effective_to}}
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Company Experience (Years)"
                      label-for="company-experience"
                    >
                    {{previous_employeement.experience}}
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="PF Number" label-for="pf-number">
                      {{previous_employeement.pf_number}}
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Company Address">
                      {{previous_employeement.company_address}}
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Leaving Reason">
                      {{previous_employeement.leaving_reason}}
                    </b-form-group>
                  </b-col>
                </b-row>
                </div>
                </b-col>
              </b-row>
            </validation-observer>
          </b-tab>
          <b-tab >
            <template #title >
              <strong style="margin-right: auto;" >Documents</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col md="12" >
                  <div style="border: 2px solid #38B22D;padding: 15px;border-radius: 50px 20px;" 
                class="mt-1" v-for="(document,k) in data_local.documents" :key="k">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Document Name" label-for="document-name">
                      {{document.document_name}}
                    </b-form-group>

                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Description">
                      {{document.description}}
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <!-- <b-form-group
                    v-if="!document.file"
                    label="Upload Document"
                    label-for="files"
                  >
                    <file-pond
                      ref="pond"
                      name="files[]"
                      max-file-size="5MB"
                      max-files="1"
                      allow-multiple="false"
                      image-preview-height="120px"
                    />
                    <small class="text-warning flex items-center">
                      <feather-icon icon="AlertCircleIcon" class="mr-25" />
                      File size should be less than 5MB.
                    </small>
                  </b-form-group> -->
  
                  <div>
                    Uploaded Document
                    <feather-icon
                      v-b-tooltip.hover.top="'View Document'"
                      class="btn-link cursor-pointer ml-1"
                      icon="EyeIcon"
                      @click.stop.prevent="
                        openWindow(document.file)
                      "
                    />
                  </div>
                  </b-col>
                </b-row>
                </div>
                </b-col>
              </b-row>
            </validation-observer>
          </b-tab>
          <b-tab>
            <template #title >
              <strong style="margin-right: auto;" >Resignation Details</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col md="6">
                    <b-form-group
                    label="Submitted on Date"
                    label-for="submitted-date"
                  >
                    {{data_local.resignation.submitted_on}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-checkbox class="mt-2"
                    v-model="data_local.resignation.is_notice_required"
                    value='yes'
                    disabled
                  >
                    Notice Required
                  </b-form-checkbox>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Notice Period (Days)"
                    label-for="notice-period"
                  >
                    {{data_local.resignation.notice_period_days}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Short Fall in Notice Period (Days)"
                    label-for="short-fall-notice-period"
                  >
                    {{data_local.resignation.short_fall_notice_period_days}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                  label="Tentative Leaving Date"
                  label-for="tentative-date"
                >
                  {{data_local.resignation.tentative_date}}
                </b-form-group>
              </b-col>
              <b-col md="6">
                
                <b-form-checkbox class="mt-2"
                  v-model="data_local.resignation.is_exlude_from_final_settlement"
                  value='yes'
                  disabled
                >
                Exlude From Final Settlement
                </b-form-checkbox>
              </b-col>
              <b-col md="6">
                <b-form-group label="Leaving Reason">
                  {{data_local.resignation.leaving_reason}}
                </b-form-group>
              </b-col>  
              <b-col md="6">
                <b-form-group label="Remarks">
                  {{data_local.resignation.remarks}}
                </b-form-group>
              </b-col>
              </b-row>
            </validation-observer>
          </b-tab>
  
          <b-tab>
            <template #title >
              <strong style="margin-right: auto;" >Exit Details</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col md="6">
                    <b-form-group
                    label="Leaving Date"
                    label-for="leaving-date"
                  >
                    {{data_local.exit_details.leaving}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                  label="Settled Date"
                  label-for="settled-date"
                  >
                    {{data_local.exit_details.leaving}}
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-checkbox class="mt-2"
                    v-model="data_local.exit_details.notice_served"
                    value='yes'
                    disabled
                  >
                    Notice Served
                  </b-form-checkbox>
                </b-col>
                <b-col md="6">
                  <b-form-checkbox class="mt-2"
                    v-model="data_local.exit_details.has_left_the_company"
                    value="yes"
                    disabled
                  >
                    Has left the company
                  </b-form-checkbox>
                </b-col>
  
                <b-col md="6">
                  <b-form-checkbox class="mt-2"
                    v-model="data_local.exit_details.fit_to_be_rehired"
                    value="yes"
                    disabled
                  >
                    Fit to be ReHired.
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </validation-observer>
          </b-tab>
          <b-tab>
            <template #title >
              <strong style="margin-right: auto;" >Exit Interview</strong>
            </template>
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col md="6">
                  <b-form-group
                  label="Interview Date"
                  label-for="interview-date"
                  >
                    {{data_local.exit_interview_date}}
                  </b-form-group>
                </b-col>
  
                <b-col md="6">
                  <b-form-group label="Remarks">
                    {{data_local.exit_interview_remarks}}
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </b-tab>
        </b-tabs>
    </b-card>
  </div>
</template>


<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import vSelect from "vue-select";
import employeeStoreModule from "../employeeStoreModule";
import Ripple from "vue-ripple-directive";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (h) => h("feather-icon", { props: { size: "14", icon: "XIcon" } }),
  },
  OpenIndicator: {
    render: (h) =>
      h("feather-icon", {
        props: { size: "15", icon: "ChevronDownIcon" },
        class: "open-indicator",
      }),
  },
});

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      userId:"",
      numeric,
      faqSearchQuery: "",
      faqData: {},
      data_local: {
        name: "",
        email: "",
        status: true,
        avatar: "",
        employee_id: "",
        reporting_manager: "",
        date_of_join: "",
        probation_days: "",
        confirmation_date: "",
        date_of_birth: "",
        mobile: "",
        gender: "",
        emergency_contact_name: "",
        emergency_contact_number: "",
        father_name: "",
        spouse_name: "",
        designation_id: "",
        location: {
          add_line_1: "",
          add_line_2: "",
          post_code: "",
          city: "",
          state: "",
          country: "India",
        },
        employee_type: "",
        permanent_address: {
          add_line_1: "",
          add_line_2: "",
          post_code: "",
          city: "",
          state: "",
          country: "",
        },
        
      emergency_contact_address: {
        add_line_1: "",
        add_line_2: "",
        post_code: "",
        city: "",
        state: "",
        country: "",
      },
      background_check:{
          verification_status:"",
          agency_name:"",
          remarks:"",
          verified_on:""
        },
      position_history:[
        {
          designation_id:'',
          designation:'',
          effective_from:'',
          effective_to:'',
        }
      ],
      previous_employeements:[
        {
          designation_id:'',
          designation:'',
          effective_from:'',
          effective_to:'',
          company_name:'',
          experience:0,
          leaving_reason:'',
          company_address:'',
          pf_number:'',
        }
      ],
      documents:[
        {
          document_name:'',
          description:'',
          file:null,
        }
      ],
      resignation:{
        is_notice_required:'',
        submitted_on:'',
        leaving_reason:'',
        remarks:'',
        tentative_date:'',
        short_fall_notice_period_days:'',
        is_exlude_from_final_settlement:'',
        notice_period_days:''       
      },
      exit_details:{
        leaving_date:'',
        settled_on:'',
        notice_served:'',
        has_left_the_company:'',
        fit_to_be_rehired:'',
      },
      },
      checklist_description_ids: [],
      list_shifts: [],
      designationOptions: [],
      list_employee_types: [
        { label: "Permanent", code: "permanent" },
        { label: "Contract", code: "contract" },
      ],
      okr_data:[],
      employeeLeavesList: [],
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-employees";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, employeeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  created() {
    this.userId = this.$route.params.id;
    this.fetch_user_data(this.$route.params.id);
    this.fetchEmployeeLeaves(this.$route.params.id);
    // const queryParams = { q: trimmedStr, type: type }
  },
  methods: {
    generateUrl(name, query) {
      const routeData = this.$router.resolve({
        name: name,
        query: query
      });
      return routeData.href;
    },
    viewRecord(okr) {
      //this.$router.go(`/view/okr/${okr.hashid}`).catch(() => {});
      let routeData = this.$router.resolve({name: 'view-okr', params: {id: okr.hashid}});
      window.open(routeData.href, '_blank');
    },
    
  fetchEmployeeLeaves(hashid) {
  console.log('fetchEmployeeLeaves called with hashid:', hashid);
  if (hashid) {
    this.$http.get(`/employee-leaves/${hashid}`, {
        params: {
          year: this.sessionFilter,
        }
      }).then((res) => {
        console.log('Response from fetchEmployeeLeaves:', res);
        this.employeeLeavesList = res.data.data;
      })
      .catch((err) => {
        console.error('Error fetching employee leaves:', err);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Fetch Employee List failed.",
            icon: "BellIcon",
            variant: "danger",
            text: err.toString(),
          },
        });
      });
  } else {
    console.error('Hashid is undefined or null');
  }
},
    fetch_user_data(userId) {
      this.$store
        .dispatch("app-employees/fetchEmployee", userId)
        .then((res) => {
          this.data_local = res.data.data;
          this.okr_data = res.data.data.user_okrs;
        })
        .catch((err) => {
          if (err.response.status === 404) {
            return;
          }
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
    formSubmitted() {
      this.data_local._method = "PATCH";
      this.$store
        .dispatch("app-employees/updateEmployee", {
          data: this.data_local,
          userId: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Employee Updated",
                icon: "BellIcon",
                variant: "success",
                text: res.data.message,
              },
            });
            this.$router.push("/employees");
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Oops! Employee updating Failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Employee Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchData() {
      this.$http
        .get("/checklistform", { params: { q: this.faqSearchQuery } })
        .then((res) => {
          this.faqData = res.data.data;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/page-faq.scss";

.employee-view .nav-vertical .nav.nav-tabs .nav-item .nav-link{
  justify-content: flex-start;
}

.employee-view .faq-search-input{
  max-width: 500px;
  margin: 0 auto;
}
</style>
